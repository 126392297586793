@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./Open Sauce Sans/OpenSauceSans-Regular.ttf') format('ttf'),
       url('./Open Sauce Sans/OpenSauceSans-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

.sidebar {
  width: 300px;
  height: 100vh;
  position: fixed;
  padding: 20px;
  background-color: rgba(249, 248, 246, 1);
  top: 0;
  left: 0;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #E5E4E1;
}

.svg-icon {
  padding-right: 10px;
}

.active-icon {
  filter: invert(100%) brightness(0);
}

.active-text {
  color: black;
}

.logo {
  width: 90%;
  display: block;
  margin-left: -6px;
}

.sidebar h2 {
  font-family: 'Open Sauce Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: -0.02em;
  color: rgba(77, 77, 77, 0.8);
  text-align: left;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}


.sidebar ul {
  list-style: none;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  letter-spacing: -0.02em;
}

.sidebar a {
  font-family: 'Open Sauce Sans', sans-serif;
  color: rgba(77, 77, 77, 0.8);
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
}

.sidebar a.active-text {
  color: rgb(93, 93, 93);
}

.sidebar li {
  margin-bottom: 10px;
  /* Spacing between items */
}

.sidebar li a:active,
.sidebar li a:focus,
.sidebar li a.selected {
  color: black;
  outline: none;
}

* {
  box-sizing: border-box;
}

.sidebar input[type="email"] {
  width: 100%;
  padding: 6.42px 9.63px;
  height: 48px;
  margin-top: 10px;
  border: 1px solid rgba(205, 207, 213, 0.5);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  background-color: #ffffff;
  color: #333;
  font-family: 'Open Sauce Sans', sans-serif;
}

.sidebar input:focus {
	animation: glow 800ms ease-out infinite alternate;
  color: #333;
  background: #ffffff;
  outline: none;
}


@keyframes glow {
    0% {
        border-color: rgb(210, 166, 166);
        box-shadow: 0 0 5px rgba(255, 194, 194, 0.5), inset 0 0 5px rgba(255, 134, 134, 0.5), 0 1px 0 #b1b1b1;
    }
    100% {
        border-color: rgb(247, 162, 162);
        box-shadow: 0 0 20px rgba(255, 0, 0, 0.6), inset 0 0 10px rgba(255, 137, 137, 0.4), 0 1px 0 #b5b5b5;
    }
}

/* Ensuring buttons maintain the same style */
.design-services-button, .subscribe-button {
  width: 100%;
  padding: 6.42px 9.63px;
  height: 48px;
  margin-top: 10px;
  border: 1px solid rgba(205, 207, 213, 0.5);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}


.design-services-button {
  background-color: rgba(213, 67, 65, 1);
  font-family: 'Open Sauce Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: white;
  height: 48px;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 6px;
  background:
    linear-gradient(rgba(239, 3, 0, 0.7), rgba(239, 3, 0, 0.7)),
    url('./buttontexture.png');
  background-blend-mode: multiply;
  box-shadow: 0px -1px 4px 0px #8F0E0B inset, 0px -3px 5px 0px rgba(255, 255, 255, 0.25) inset, 0px 1px 0px 1px #8F0E0B;
}


.sidebar .subscribe-button {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(77, 77, 77, 0.8);
  border: 1px solid rgba(213, 213, 205, 0.5);
  box-shadow: 0px -4px 0px 0px rgba(239, 239, 239, 1) inset;
  margin-bottom: 20px;
  position: relative;
  font-family: 'Open Sauce Sans', sans-serif;
  font-weight: 700;
}

.sidebar .subscribe-button:hover {
  background: linear-gradient(0deg, #FFF 0%, #F0F0F0 100%);
}

.sidebar .subscribe-button::after { /* Line between buttons */
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  border-bottom: 1px solid rgba(205, 207, 213, 0.5);
  width: 100%;
  color: rgba(62, 69, 105, 1);
}

.sidebar .design-services-button {
  margin-bottom: 40px;

}

.content {
  margin-left: 300px;
  width: calc(100% - 300px);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(249, 248, 246, 1);
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

@media (max-width: 900px) {
  .sidebar {
    background-color: rgba(249, 248, 246, 1);
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100vh;
  }

  .sidebar.active {
    transform: translateX(0);
  }

  .logo {
    display: none;
  }
   
  .sidebar-top {
    padding: 0;
    padding-top: 50px;
  }

  .sidebar-bottom {
    padding: 0;
  }

  .header-open {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(249, 248, 246, 1);
    z-index: 1000;
    border-bottom: 1px solid rgba(205, 207, 213, 0.5);
  }

  .close-button {
    color: rgba(213, 67, 65, 1);
    background-color: rgba(255, 255, 255, 1);
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
}

/* Add styles for the mobile menu trigger that should always be visible below 900px */
@media (max-width: 900px) {
  .mobile-menu-trigger {
    display: block;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 999;
  }
}
