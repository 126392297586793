@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');

body,
html {
  margin: 0;
  padding: 0;

  width: 100%;
  height: 100%;

  overflow-x: hidden; /* No horizontal scroll */
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./Open Sauce Sans/OpenSauceSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Sans';
  src: url('./Open Sauce Sans/OpenSauceSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.sidebar {
  width: 300px;/* Fixed width for larger screens */
  height: 100vh;
  position: fixed;
  background-color: rgba(249, 248, 246, 1);
  top: 0;
  left: 0;
  overflow-y: auto;
  z-index: 500;
}

.content {
  margin-left: 300px;
  width: calc(100% - 300px);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(249, 248, 246, 1);
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.content::before { /* Blur Image on Background */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url('./blur.png'); */ /* Commented out to remove background image */
  background-size: cover; /* Ensures the image covers the whole area */
  background-repeat: repeat; /* Prevents repeating the image */
  background-position: center; /* Centers the image */
  opacity: 0.3; /* Sets the opacity to 30% */
  z-index: -1; /* Places the pseudo-element behind all content */
  pointer-events: none; /* Allows interaction with elements above */
}

.filter-title {
  font-family: 'Open Sauce Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  letter-spacing: -0.03em;
  align-self: flex-start;
  margin-left: 20px;
  color: rgba(77, 77, 77, 0.8);
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  justify-items: center;
}

.blog-post {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  border: 1px solid rgba(213, 213, 205, 0.5);
  box-shadow: 0px -4px 0px 0px rgba(239, 239, 239, 1) inset;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  height: 136px;
  background-color: rgba(255, 255, 255, 1);
}

.blog-post-image {
  width: 108px;
  height: 108px;
  margin-right: 20px;
  border-radius: 4px;
  object-fit: cover;
}

.blog-post-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  margin: 0;
  width: 100%;
}

.blog-post-ideas {
  display: flex;
  flex-direction: column; /* Aligns children vertically */
  align-items: center; /* Centers children horizontally */
  height: 300px;
}

.blog-post-ideas-image {
  margin-right: 0px; 
  height: 65%;
  width: 100%;
}

.blog-title,
.blog-post-ideas-title {
  font-family: 'Open Sauce Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 160%;
  text-align: left;
  color: rgb(0, 0, 0);
}

.blog-summary {
  font-family: 'Open Sauce Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: rgba(92, 92, 92, 0.7);
  margin: 0px;
}

.sidebar-open .sidebar {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background-color: rgba(249, 248, 246, 1);
}

/* INDIVIDUAL POSTS */

.individual-post {
  max-width: 680px;
  margin: 0 auto;
  padding: 20px 0;
  padding-top: 0px;
  font-family: 'Open Sauce Sans', sans-serif;
  line-height: 140%;
  color: #333;
  letter-spacing: -0.02%;
}

/* Styles for markdown content within individual posts */
.individual-post p {
  margin-bottom: 15px;
  letter-spacing: -0.02%;
  line-height: 160%;
}

.individual-post h1,
.individual-post h2,
.individual-post h3 {
  letter-spacing: -0.5px;
  margin-top: 0px;
  margin-bottom: 16px;
}

.individual-post-return {
  width: 93px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid rgba(216, 216, 216, 0.5);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -2px 0px 2px rgba(229, 229, 229, 1) inset;
  opacity: 1; /* Adjusted opacity for visibility */
  cursor: pointer;

  font-family: 'Open Sauce Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: rgba(77, 77, 77, 1);
  cursor: pointer;
  text-align: center;
}

.individual-post-title {
  padding-top: 15px;
  font-family: 'Open Sauce Sans', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
  color: #333;
}

.individual-post-image {
  height: auto;
  width: 100%;
}

.individual-post-image-slideshow {
  width: 100%;  /* Ensures the image stretches to fill the container */
  height: 400px; /* Fixed height for all images */
  object-fit: cover; /* Crop to cover the container without distorting aspect ratio */
}

.post-content {
  font-family: 'Open Sauce Sans';
  font-size: 16px;
  font-weight: 200;
  line-height: 160%;
  letter-spacing: 0.01em;
  text-align: left;
  color: #333; 
  overflow-y: auto;
  width: 100%;
  position: relative;
  overflow: visible;
}

/* Medium screens */
@media (max-width: 1400px) {
  .blog-posts {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    justify-items: center;
  }

  .filter-title {
    padding-left: 0px;
  }

  .individual-post {
    padding-left: 20%;
    padding-right: 20%;
  }
}
@media (max-width: 1100px) {
  .individual-post {
    padding-left: 10%;
    padding-right: 10%;
  }
}


/* Small Screens */
@media (max-width: 900px) {
  .blog-posts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sidebar {
    display: none; /* Hide sidebar */
    width: 100vw; /* Full width when visible */
    height: 100vh;
    z-index: 1000; /* Ensure it's above other content */
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensures the logo and icon are spaced apart */
    width: 100%;
    padding: 10px 0px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(249, 248, 246, 1);
    z-index: 1000;
    border-bottom: 1px solid rgba(205, 207, 213, 0.5);
  }

  .logo-container {
    display: flex;
    align-items: center; /* Vertically centers the logo */
  }

  .ux-logo {
    content: url('./mainlogo.png'); /* Added .jpg extension */
    max-width: 200px; /* Adjust size as needed */
    height: auto;
    padding-left: 10%;
  }

  .icon-container {
    display: flex;
    align-items: center; /* Vertically centers the hamburger icon */
    padding-right: 20px;
  }

  .toggle-sidebar-btn {
    background-color: rgba(255, 255, 255, 1); /* White background */
    border: 1.17px solid rgba(216, 216, 216, 0.5); /* Border with specified color */
    box-shadow: 0px -1.17px 0px 1.17px rgba(229, 229, 229, 1) inset; /* Box shadow as per the spec */
    width: 42px; /* Width of the button */
    height: 42px; /* Height of the button */
    padding: 11px; /* Padding */
    border-radius: 7px; /* Border radius */
    top: 26px;
    left: 328px;
    cursor: pointer;
  }

  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 18px;
    height: 16px;
  }

  .hamburger-icon span {
    background-color: rgba(213, 67, 65, 1); /* Change color to match your design */
    height: 2px;
    width: 100%;
    display: block;
  }

  .content {
    margin-left: 0;
    width: 100%;
    padding: 90px 20px 20px 20px;  /* Match the sidebar's padding */
  }

  .blog-title {
    font-family: 'Open Sauce Sans', sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 33.6px;
    text-align: left;
    color: black;
    margin: 0;
    white-space: normal;    /* Allow text to wrap */
    overflow: visible;      /* Show all content */
    overflow-wrap: break-word; /* Modern syntax */
    width: 100%;
  }
  
  .blog-post-ideas-title {
    font-size: 20px;
    white-space: normal;    /* Allow text to wrap */
    overflow: visible;      /* Show all content */
    text-overflow: clip;    /* Remove ellipsis */
  }
  
  .blog-summary {
    font-family: 'Open Sauce Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    color: rgba(83, 83, 83, 0.7);
    margin: 0px;
    white-space: normal;    /* Allow text to wrap */
    overflow: visible;      /* Show all content */
    text-overflow: clip;    /* Remove ellipsis */
  }

  /* INDIVIDUAL POSTS */
  .individual-post-title {
    padding-top: 20px;
    font-family: 'Open Sauce Sans', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 150%;
    color: #333;
  }

.individual-post h1,
.individual-post h2,
.individual-post h3 {
  letter-spacing: -0.5px;
  margin-top: 40px;
}

  .individual-post {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .individual-post-image {
    height: 200px;
    width: 100%;
  }

  .individual-post-image-slideshow {
    height: 200px;
    width: 100%;
    object-fit: cover; /* Crop to cover the container without distorting aspect ratio */
  }

  /* Make images full width on mobile */
  .post-content img {
    width: 100vw; /* Full viewport width */
    max-width: none;
    margin: 20px 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    /* Calculate negative margin to offset parent padding */
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }

  .blog-posts {
    padding: 0 20px;  /* Match the sidebar's padding */
    width: 100%;
    box-sizing: border-box;
  }

  /* If you have the no-scroll class */
  .content.no-scroll {
    padding: 90px 20px 20px 20px;
  }

  .blog-posts.ideas-and-principles,
  .blog-posts.stories,
  .blog-posts.roasts {
    padding: 0;
    margin-top: 12px;
    width: 100%;
  }

  /* Remove padding for the filter titles */
  .filter-title {
    padding: 0;
    margin: 0;
  }
}

.slideshow-container {
  position: relative;
  width: 100%; /* Adjust width as needed */
  max-width: 600px; /* Adjust max width as needed */
  margin: auto; /* Center the slideshow */
}

.slide {
  position: relative;
  width: 100%;
}

.prev, .next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: transparent;
  border: none;
  color: white; /* Adjust color as needed */
  font-size: 24px; /* Adjust font size as needed */
  cursor: pointer;
  padding: 10px;
  transition: transform 0.2s;
}

.prev:hover, .next:hover {
  transform: translateY(-50%) scale(1.2); /* Slight scale effect on hover */
}

.prev {
  left: 10px; /* Adjust distance from the left edge */
}

.next {
  right: 10px; /* Adjust distance from the right edge */
}

.blog-post-shared {
  display: flex;
  flex-direction: column; /* Aligns children vertically */
  align-items: center; /* Centers children horizontally */
  height: 300px; /* Ensure consistent height */
  border-radius: 4px;
  border: 1px solid rgba(213, 213, 205, 0.5);
  box-shadow: 0px -4px 0px 0px rgba(239, 239, 239, 1) inset;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
}

.blog-post-ideas-image,
.blog-post-image {
  margin-right: 0px; 
  height: 65%;
  width: 100%;
  object-fit: cover;
}

.text-center {
  text-align: center;
  width: 100%;
}

.text-left {
  text-align: left;
  width: 100%;
}

.logo-container .ux-logo {
  width: 120px;  /* Increased from 72px for mobile */
  height: auto;
  padding-left: 20px;
  content: none;
}

/* Desktop size can remain the same */
@media screen and (min-width: 900px) {
  .logo-container .ux-logo {
    width: 120px;
  }
}

.header-open {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures the logo and icon are spaced apart */
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(249, 248, 246, 1);
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
}

.ux-logo {
  width: 120px;
  height: auto;
  padding-left: 20px;
  content: none;
}

.icon-container {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.header, .header-open {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.header {
  background-color: rgba(249, 248, 246, 1);
  border-bottom: 1px solid rgba(205, 207, 213, 0.5);
}

.header-open {
  background-color: rgba(249, 248, 246, 1);
}

/* Base styles for all markdown content */
.post-content h2 {
  line-height: 160%;
  font-size: 22px;
  letter-spacing: -0.02%;
  font-family: 'Open Sauce Sans', sans-serif;
}

/* Only need specific styles for unique cases */
.post-content blockquote {
  border-left: 4px solid #ddd;
  padding-left: 16px;
  margin-left: 0;
}

.post-content pre,
.post-content code {
  font-family: monospace;
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
}

/* Style for images within post content */
.post-content img {
  width: 120%;
  height: auto;
  margin: 30px 0;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Make sure images are responsive on mobile */
@media (max-width: 900px) {
  .post-content img {
    width: 100%;
    margin: 20px 0;
  }
}

@media (max-width: 900px) {
  .content.allow-scroll {
    margin-left: 0;
    width: 100%;
    padding: 20px 20px 20px 20px; /* Match the header padding */
  }

  /* Make post content align with header elements */
  .content.allow-scroll .individual-post {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  /* Full viewport images */
  .content.allow-scroll .post-content img {
    width: 100vw;
    max-width: none;
    position: center;
    transform: translateX(-50%);
    margin-left: 0px;
    margin-right: 0px;
  }

  /* Remove padding from post-content */
  .content.allow-scroll .post-content {
    padding: 0;
  }
}

.post-content p {
  font-size: 15px;
  line-height: 180%;
}